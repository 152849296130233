import React, { useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import Logo from "../../src/assets/Amaan-Logo.png";
import bullionStats from "../../src/assets/bullionStats.png";
import { Box, Fade, Modal, Typography, useMediaQuery } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment-timezone";
import LinearProgress from "@mui/material/LinearProgress";
import Marquee from "react-fast-marquee";
import { useEffect } from "react";
import io from "socket.io-client";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon copy";
import SubscriptionExpired from "./SubscriptionExpired";
import Carousel from "react-material-ui-carousel";
const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    width: "100%",
  },
  parentContainerError: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "black",
  },
  logoSection: {
    height: "20%",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    alignItems: "center",
    margin: "0 2em",
  },
  newsSection: {
    height: "8%",
    backgroundColor: "#202123",
    display: "flex",
  },

  valueShowingSection: {
    height: "60%",
    padding: "1em 3em 0 3em",
    display: "grid",
    gap: "40px",
    gridTemplateColumns: "2fr 1fr",
    gridTemplateAreas: `
      'valueSection ImageSection'
     `,
  },
  valueSection: {
    gridArea: `valueSection`,
    height: "100%",
    display: "grid",
    gap: "0.9em",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
  },
  spotPrices: {
    height: "37%",
    gridArea: `spotPrices`,
    display: "grid",
    gap: "1em",
    gridTemplateColumns: "2fr 1fr",
    gridTemplateAreas: `
      'goldSpot silverSpot'
     `,
  },
  goldSpot: {
    gridArea: `goldSpot`,
    borderRadius: "30px",
    backgroundColor: "#202123",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  goldSpotBody: {
    padding: "0 20px 0 20px",
    display: "flex",
    height: "75%",
    justifyContent: "space-evenly",
  },

  spotBodyValues: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  silverSpot: {
    gridArea: `silverSpot`,
    backgroundColor: "#202123",
    borderRadius: "30px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  silverSpotBody: {
    padding: "0 20px 0 20px",
    display: "flex",
    height: "75%",
    justifyContent: "space-evenly",
  },

  tableValues: {
    gridArea: `tableValues`,
    height: "57%",
  },

  table: {
    gridArea: "table",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 4fr",
    gridTemplateAreas: `
        'tabeHeader' 
        'tableContent'
      `,
    gap: "0.5em",
    height: "93%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#202123",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    paddingLeft: "20px",
  },

  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "flex-start",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "24%",
    flexGrow: 1,
    gridArea: "tableRow1",
    background: "#FFFFFF",
    borderBottom: "3px solid #000000",
    display: "flex",
  },

  tableRow2: {
    flexBasis: 0,
    minHeight: "24%",
    flexGrow: 1,
    gridArea: "tableRow1",
    background: "#FFFFFF",
    borderBottom: "3px solid #000000",
    display: "flex",
  },

  ImageSection: {
    gridArea: `ImageSection`,
    height: "100%",
    display: "grid",
    gridTemplateRows: "2fr 1fr",
    gridTemplateAreas: `
            'goldImage'
            'BuySellData'
         
         `,
  },
  goldImage: {
    gridArea: `goldImage`,
    borderRadius: "30px",
    maxHeight: "100%",
    minHeight: "100%",
  },
  BuySellData: {
    gridArea: `BuySellData`,
    height: "71%",
    width: "100%",
    marginTop: ".9em",
    background: "#202123",
  },
  stats: {
    padding: "1rem",
  },
  footerSection: {
    height: "8%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#202123",
  },
  footerSectionCopyRights: {
    color: "#FFFFFF",
    fontFamily: "Calibri",
    fontSize: "2vw",
    display: "flex",
    alignItems: "center",
  },
  footerSectionBullionLogo: {
    display: "flex",
    alignItems: "center",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [silver, setSilver] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousSilver, setPreviousSilver] = useState();
  const [previousGold, setPreviousGold] = useState();
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [time, setTime] = useState(new Date());
  const [isErrorOccured, setIsErrorOccured] = useState(false);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [gold, setGold] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  useEffect(() => {
    handleFindSocketUrl();
    handleFinsCommodities();
    handleFindSpread();
    handleFindRatio();
    hadlefetchNews();
    handelFindBanners();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useMemo(() => {
    if (socket) {
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err}`);
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", ({ data }) => {
        setIsErrorOccured(data.errorOccured);
        setIsMarketOpen(data?.isMarketOpen);
        setPreviousGold(gold);
        setGold({
          ask: data?.ask,
          bid: data?.bid,
          highPrice: data?.highPrice,
          lowPrice: data?.lowPrice,
        });
      });
      socket.on("silver-rate-change", ({ data }) => {
        setPreviousSilver(silver);
        setSilver({
          ask: data?.ask,
          bid: data?.bid,
          highPrice: data?.highPrice,
          lowPrice: data?.lowPrice,
        });
      });
    }
  }, [gold, silver, socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });

    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
  }, []);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );
        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        // const timeRemaining = duration - elapsedTime;
        if (elapsedTime <= 0) {
          // Timer completed
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      });
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      if (!socket) {
        socket = io(res?.socketURL);
        Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
      }
    }
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (date) => {
    return date.toLocaleString([], {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  ////////////Block//////
  const [openBlocked, setOpenBlocked] = useState(false);
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ////////////////////////////////////////////////////////////

  const [adminData, setAdminData] = useState();
  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        // handleExpireSoonOpen();
      }
    }
  };

  ////////////////////////////////////////////////////////////

  return (
    <div
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.parentContainer} component="section">
        {!isMarketOpen && (
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "-125px",
              right: "0",
              bottom: "0",
              width: "895px",
              height: "531px",
              overflow: "hidden",
              zIndex: "99",
            }}
          >
            <div
              style={{
                background: "#202123",
                position: "relative",
                width: "100%",
                height: "10%",
                transform: "rotate(-35deg)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFFFFF",
                fontSize: "2vw",
                fontWeight: "bold",
                padding: "20px",
                lineHeight: 1.5,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Marquee delay="2" gradient={false}>
                {remainingTime && (
                  <p style={{ marginLeft: "90px" }}>
                    Market closed! Opens on
                    {formatRemainingTime(remainingTime)}
                  </p>
                )}
              </Marquee>
            </div>
          </Box>
        )}
        <Box className={classes.logoSection} component="section">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#202123",
            }}
          >
            <Typography
              sx={{
                fontSize: "2.2vw",
                fontWeight: "bold",
                color: "white",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {formatDay(time)
                .split("")
                .map((item, index) => (
                  <span key={index}>{item.toUpperCase()}</span>
                ))}
            </Typography>
            <Typography
              sx={{
                fontSize: "2.2vw",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {formatDate(time).toUpperCase()}
            </Typography>
          </Box>
          <img style={{ maxHeight: "100%" }} src={Logo} alt="Amaan-gold-logo" />
          <Box
            sx={{
              display: "flex",
              color: "#202123",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <AccessTimeIcon style={{ fontSize: "60px", color: "white" }} />
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "2.5vw",
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
              {formatTime(time)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.newsSection} component="section">
          <Marquee
            speed={50}
            gradient={false}
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Calibri",
              fontStyle: "italic",
              color: "#FFFFFF",
              fontWeight: "400",
              fontSize: "2vw",
            }}
          >
            {news?.map((_row, index) => (
              <>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {news[0]?.newsTitle}
                </span>
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <span>{news[0]?.newsDetails}</span>
                <span style={{ marginRight: "50px", marginLeft: "50px" }}>
                  {index === news.length - 1 ? "" : "|"}
                </span>
              </>
            ))}
          </Marquee>
        </Box>
        <Box className={classes.valueShowingSection} component="section">
          <Box className={classes.valueSection} component="article">
            <Box className={classes.spotPrices} component="article">
              <Box className={classes.goldSpot} component="article">
                <Typography
                  sx={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    whiteSpace: "nowrap",
                    borderRadius: "30px 30px",
                    fontFamily: "Calibri",
                    fontWeight: "900",
                    color: "#202123",
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    bottom: "8px",
                    width: "45%",
                    height: "1.5em",
                    fontSize: "1.6vw",
                  }}
                >
                  GOLD SPOT
                </Typography>
                <Box className={classes.goldSpotBody} component="section">
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "2.5vw",
                      }}
                    >
                      {displayBidOrBuy.bidOrBuy.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        marginBottom: "0.3em",
                        transition: "background-color 0.5s ease",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                        padding: "0 0.5em 0 0.5em",
                        border: "1px solid #FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(gold?.bid).toFixed(2) <
                          Number(previousGold?.bid).toFixed(2)
                            ? "#DE1616"
                            : Number(gold?.bid).toFixed(2) >
                              Number(previousGold?.bid).toFixed(2)
                            ? "#5FA147"
                            : "#202123",
                      }}
                    >
                      {(
                        Number(spread.goldBidSpread) + Number(gold.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "2.5vw",
                      }}
                    >
                      {displayBidOrBuy.askOrSell.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        marginBottom: "0.3em",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                        border: "1px solid #FFFFFF",
                        padding: "0 0.5em 0 0.3em",
                        transition: "background-color 0.5s ease",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(gold?.ask).toFixed(2) <
                          Number(previousGold?.ask).toFixed(2)
                            ? "#DE1616"
                            : Number(gold?.ask).toFixed(2) >
                              Number(previousGold?.ask).toFixed(2)
                            ? "#5FA147"
                            : "#202123",
                      }}
                    >
                      {(
                        Number(spread.goldAskSpread) + Number(gold.ask)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "1.6vw",
                      }}
                    >
                      TODAY
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "1.8vw",
                        padding: "0 0.5em 0 0.3em",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.5em",
                        }}
                      >
                        H:
                        {(
                          Number(spread.goldHighSpread) + Number(gold.highPrice)
                        ).toFixed(2)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.5em",
                        }}
                      >
                        L:
                        {(
                          Number(spread.goldLowSpread) + Number(gold.lowPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.silverSpot} component="article">
                <Typography
                  sx={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    whiteSpace: "nowrap",
                    borderRadius: "30px 30px",
                    fontFamily: "Calibri",
                    fontWeight: "900",
                    color: "#202123",
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    bottom: "8px",
                    width: "45%",
                    height: "1.5em",
                    fontSize: "1.3vw",
                  }}
                >
                  SILVER SPOT
                </Typography>
                <Box className={classes.silverSpotBody} component="section">
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontSize: "1.4vw",
                      }}
                    >
                      {displayBidOrBuy.bidOrBuy.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontSize: "1.4vw",
                        padding: "0 0.5em 0 0.5em",
                        transition: "background-color 0.5s ease",
                        border: "1px solid #FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(silver?.bid).toFixed(2) <
                          Number(previousSilver?.bid).toFixed(2)
                            ? "#DE1616"
                            : Number(silver?.bid).toFixed(2) >
                              Number(previousSilver?.bid).toFixed(2)
                            ? "#5FA147"
                            : "#202123",
                      }}
                    >
                      {(
                        Number(spread.silverBidSpread) + Number(silver.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontSize: "1.4vw",
                      }}
                    >
                      {displayBidOrBuy.askOrSell.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Calibri",
                        fontSize: "1.4vw",
                        padding: "0 0.5em 0 0.3em",
                        transition: "background-color 0.5s ease",
                        border: "1px solid #FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(silver?.ask).toFixed(2) <
                          Number(previousSilver?.ask).toFixed(2)
                            ? "#DE1616"
                            : Number(silver?.ask).toFixed(2) >
                              Number(previousSilver?.ask).toFixed(2)
                            ? "#5FA147"
                            : "#202123",
                      }}
                    >
                      {(
                        Number(spread.silverAskSpread) + Number(silver.ask)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontSize: "1.3vw",
                      }}
                    >
                      TODAY
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Calibri",
                        fontSize: "1.6vw",
                        padding: "0 0.5em 0 0.3em",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.4em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        H:
                        {(
                          Number(spread.silverHighSpread) +
                          Number(silver.highPrice)
                        ).toFixed(2)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.4em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        L:
                        {(
                          Number(spread.silverLowSpread) +
                          Number(silver.lowPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.tableValues} component="article">
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      fontSize: "2vw",
                      fontWeight: "bold",
                      color: "#D4A638",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      fontSize: "2vw",
                      fontWeight: "bold",
                      color: "#D4A638",
                    }}
                  >
                    WEIGHT
                  </Typography>

                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      fontSize: "2vw",
                      fontWeight: "bold",
                      color: "#D4A638",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 1 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>
                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    const isEvenRow = idx % 2 === 0;
                    return (
                      <Box
                        key={idx}
                        className={`${
                          isEvenRow ? classes.tableRow2 : classes.tableRow1
                        } ${classes.tableRow}`}
                      >
                        <Typography
                          style={{
                            fontSize: "1.4vw",
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "20px",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    color: "black",
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {word}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    color: "black",
                                    fontSize: "0.9vw",
                                    marginTop: "0.4rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;
                                  {word === "Kilobar" ||
                                  word === "Coin" ||
                                  word === "Bar"
                                    ? ""
                                    : word}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "1.4vw",
                              color: "black",
                              marginTop: "0.3em",
                              fontWeight: "bold",
                            }}
                          >
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </Typography>
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "1.7vw",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {commodity.unit}
                          {commodity.unitLabel}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "1.8vw",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {commodityCalculation(
                            commodity.commodity_title === "Silver"
                              ? silver.ask
                              : gold.ask,
                            commodity.commodity_title === "Silver"
                              ? spread.silverAskSpread
                              : spread.goldAskSpread,
                            commodity.premium,
                            3.674,
                            commodity.purity,
                            commodity.unit,
                            commodity.unitLabel === "TTB"
                              ? 116.64
                              : commodity.unitLabel === "KG"
                              ? 1000
                              : commodity.unitLabel === "OZ"
                              ? 31.1
                              : commodity.unitLabel === "TOLA"
                              ? 11.7
                              : 1,
                            commodity.charges
                          )}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.ImageSection}>
            <Box className={classes.goldImage} component="article">
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  maxHeight: "300px",
                  borderRadius: "30px",
                  minHeight: "300px",
                }}
                transitionTime={3000} // Adjust the transition time to control the speed of the fade effect (in milliseconds)
                autoPlay
                duration={2000}
              >
                {banners?.map((item, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "30px",
                    }}
                    src={item.imageUrl}
                    alt="Rectangle-Gold-Image"
                  />
                ))}
              </Carousel>
            </Box>
            <Box className={classes.BuySellData} component="article">
              <Box className={classes.stats}>
                <Box className={classes.percent}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 5,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.2vw", color: "#009E60" }}>
                      BUYERS
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        color:
                          Number(ratio?.chgValue.replace("%", "")) >= 0
                            ? "#1C6DB9"
                            : "#D03933",
                      }}
                    >
                      {ratio?.chgValue}
                    </Typography>
                    <Typography sx={{ fontSize: "1.2vw", color: "#A90011" }}>
                      SELLERS
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress
                      variant="determinate"
                      value={Number(ratio?.Buyers?.match(/\d+/)[0])}
                      style={{
                        backgroundColor: "#A90011",
                      }}
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#009E60",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 7,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.3vw", color: "#009E60" }}>
                      {ratio?.Buyers}
                    </Typography>
                    <Typography sx={{ fontSize: "1.3vw", color: "#A90011" }}>
                      {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.footerSection} component="section">
          <Box component="article">
            <Typography
              className={classes.footerSectionCopyRights}
              sx={{ marginLeft: "2.3em", fontSize: "18px" }}
            >
              &#169;2023 Amaan Gold and Diamonds , All Rights Reserved
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="article"
              className={classes.footerSectionBullionLogo}
              sx={{ marginRight: "3em" }}
            >
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "2.3em",
                }}
                src={bullionStats}
                alt="Bullion-Logo"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openSubscription}>
          <Box>
            <SubscriptionExpiringSoon
              date={adminData?.package.expire_date}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired
              handleClose={() => {
                window.location.reload();
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Home;
